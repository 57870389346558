@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

.headerindex {
	display: block;
	padding: 0;
	height: 97vh;
	@include border-box;
	margin-bottom: -5rem;
	z-index: 2;
}
.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}

.btn-home{
	height: 39px !important;
}

#sub_menu_aluguel, #sub_menu_vendas{
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	background-color: $colorSecondary;
	max-height: 450px;
	max-width: 600px;
	overflow: auto;

	& .elementos_sub_menu{
		padding: 2rem 2rem 0rem 2rem;;
		text-align: left;

		& .titletipo{
			color: white;
			font-weight: 800;
			text-transform: uppercase;
			line-height: 1rem;
			font-size: .9rem;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.5rem;
			&:hover{
				font-weight: 800;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	box-sizing: border-box;

	& .superior {
		display: flex;
		width: 90%;
		color: #ffffff;
		z-index: 3;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			padding: 1.15rem 0;

			& .img {
				max-width: 225px;
				display: block;

				& img {
					width: 100%;
					display: block;
				}
			}
		}

		/* GRUPO HEADER */
		$largura_share: 14;
		$largura_menu: 3;
		$largura_creci: 10;
		$largura_contact: 11;
		$diferenca: 17;

		& .share {
			display: flex;
			gap: 2rem;

			& .item{
				display: inline-block;
				vertical-align: middle;
				padding: 0;
				& a {
					& i {
						color: $colorPrimary;
						font-size: 1.2rem;
						transition: all .1s ease-in-out;
					}
					&:hover{
						& i {
							transform: scale(1.1);
						}
					}
				}
			}
		}

		& .creci {
			text-align: right;
			display: inline-block;
			vertical-align: middle;
			color: $gray1;
			font-size: 0.9rem;
			font-weight: 400;
		}
		& .menu {
			text-align: right;
			display: inline-block;
			vertical-align: middle;
		}

		& .sessions {
			display: flex;
			gap: 2rem;
			
			& .itemsession{
				display: inline-block;
				vertical-align: middle;
				/* padding: 0.5rem 0.8rem; */
				&:hover{
					#sub_menu_vendas, #sub_menu_aluguel {
						display: initial;
					}
				}

				& .a{
					display: block;
					width: 100%;
					font-size: .8rem;
					color: white;
					line-height: 6.6rem;
					font-weight: 600;
					padding: 0 .8rem;
					@include border-box;
					
				}
				
				& i{
					padding-left: 0.3rem;
					color: $colorSecondary;
				}

				&:hover .sub{
					display: block;
				}

				&:hover .bg{
					background-color: $colorPrimary;
					color: white !important;
					height: 6.6rem;
					line-height: 6.6rem;
				}

				&:hover .a{
					font-weight: 600;
				}


				& .sub{
					display: none;
					width: 18%;
					position: absolute;
					text-align: left;
					z-index: 9999;
					overflow: auto;
					max-height: calc(100vh - 10rem);

					& .link{
						display: block;
						width: 65%;
						background-color: white;
						color: $gray2 !important;
						padding: 0.1rem 0.8rem;
						font-size: 1rem !important;
						line-height: 2.3rem;
						transition: .15s all;
						@include border-box;

						& i{
							color: $gray1;
						}

						&:hover{
							background-color: $colorPrimary;
							color: white !important;
							font-weight: 900;
							padding-left: 1rem;
						}

						&:hover i{
							color: white;
						}
					}
				}
			}
		}

		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			text-align: center;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}

	& .busca {
		z-index: 99;
		display: block;
		position: absolute;
		width: 65%;
		left: 50%;
		top: 85%;
		text-align: center;
		transform: translate(-50%, -85%);

		& h2 {
			color: #ffffff;
			font-size: 2rem;
			text-shadow: 1px 1px $gray2;
		}

		& .busca-filtros_homepage {
			display: grid;
			grid-template-columns: 1.2fr 1.2fr 1.2fr 1fr 1fr;
			gap: .5rem;
			width: 100%;

			& .item_busca_filtros {
				width: 100%;
				display: block;
			}
		}
	}

	& .frase {
		z-index: 99;
		position: absolute;
		width: 70%;
		right: 50%;
		top: 50%;
		text-align: center;
		transform: translate(50%, 50%);
	}
}

.header-frases-arte{
	font-family: 'Quick Signature';
	color: lighten(white, 10%);
	text-shadow: 7px 2px 4px rgba(0, 0, 0, 1);
	font-size: 10rem;
	font-weight: 300;
	line-height: 30px;
}


.videoArea {
	z-index: 1;
	display: block;
	position: relative;
	width: 100%;
	height: calc(100vh - 6.5rem);
	background-color: $colorPrimary;

	.slogan_blackfriday{
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		z-index: 999;
		display: flex;
		align-items: center;
		background-color: #016678c7;
		padding: .5rem;
		border-radius: 5px;
		letter-spacing: 3px;
		
		span{
			text-decoration: underline;
		}

		h2{
			font-family: 'League Gothic', sans-serif;
			color: #fff !important;
			font-size: 1.75rem;
		}
		img {
			max-width: 75px;
		}
	}

	& .videoareaareaoverlay {
		display: block;
		z-index: 3;
		background-color:rgba(black,0.2);
		position: absolute;
		width: 100%;
		height: 100%;
	}

	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			z-index: 1;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}

		& img{
			object-fit: cover;
			width: 100%;
			display: block;
		}

		& .img{
			background-image: url('/jnh/equipe/new/equipe_2025.webp');
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			filter: brightness(1.05);
		}
	}

	& .container-header-pc{
		width: 100%;
		height: 100%;
	}
}

.container-bg-headerpc{
	display: block;
	position: absolute;
	top:0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}

.header-mob{
	background-color: white;
	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 150px;

		& img{
			width: 100%;
		}
	}
	& .btn-men{
		display: inline-block;
		vertical-align: middle;
	}
}

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $colorPrimary !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center top;
	animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}


@media all and (min-width: 1201px) and (max-width: 1450px){
	.headerindex{
		& .videoArea {
			height: calc(100vh - 5.7rem);

			& .videoareaarea {
				& .img{
					background-position: center bottom 45%;
				}
			}
		}
	}

	.headerpc {
		& .superior {
			width: 98%;
			display: flex;
			justify-content: space-around;

			& .logo {
				width: 20%;
				padding: 0;
	
				& .img {
					width: calc(1661px / 6.5);
					height: calc(443px / 6.5);
					display: block;
	
					& img {
						width: calc(1661px / 6.5);
						height: calc(443px / 6.5);
						display: block;
					}
				}
			}

			$largura_share: 7;
			$largura_creci: 7;
			$largura_menu: 3;
			$largura_contact: 11;
			$diferenca: 17;

			& .share {
				width: #{$largura_share}rem !important;
			}

			& .creci {
				width: #{$largura_creci}rem !important;
				font-size: 0.8rem;
			}

			& .sessions {
				width: calc(100% - #{(($largura_share + $largura_creci + $largura_menu + $largura_contact) + $diferenca)}rem);
				& .itemsession{
					& .a{
						font-size: .8rem;
						color: white;
						line-height: 5.7rem;
						font-weight: 600;
						padding: 0;
						@include border-box;
					}
					
					&:hover .bg{
						height: 5.7rem;
						line-height: 5.7rem;
					}
				}
			}

			& .contact{
				& .mg-tx{
					font-size: .8rem !important;
				}
				& .mg-tx2{
					font-size: .7rem !important;
				}
			}
		}
		& .busca {
			width: 80%;
			left: 50%;
			top: 90%;
			transform: translate(-50%, -90%);
	
			& h2 {
				color: #ffffff;
				font-size: 2rem;
				text-shadow: 1px 1px $gray2;
			}
	
			& .busca-filtros_homepage {
				display: grid;
				grid-template-columns: 1fr 1.2fr 1.2fr 1fr 1fr;
				gap: .5rem;
				width: 100%;
	
				& .item_busca_filtros {
					width: 100%;
					display: block;
				}
			}
		}
	}
}


@media all and (min-width: 1024px) and (max-width: 1200px){
	.headerindex{
		& .videoArea {
			height: calc(100vh - 5.7rem);

			& .videoareaarea {
				& .img{
					background-position: center bottom 45%;
				}
			}
		}
	}

	.headerpc {
		& .superior {
			width: 95%;

			& .logo {
				width: 20%;
				padding: 0;
	
				& .img {
					width: 100%;
					height: inherit;
	
					& img {
						width: 100%;
						height: inherit;
					}
				}
			}

			$largura_share: 7;
			$largura_creci: 7;
			$largura_menu: 3;
			$largura_contact: 0;
			$diferenca: 15;

			& .share {
				width: #{$largura_share}rem !important;
			}

			& .creci {
				width: #{$largura_creci}rem !important;
				font-size: 0.8rem;
			}

			& .sessions {
				width: calc(100% - #{(($largura_share + $largura_creci + $largura_menu + $largura_contact) + $diferenca)}rem);
				& .itemsession{
					& .a{
						font-size: .8rem;
						color: white;
						line-height: 5.7rem;
						font-weight: 600;
						padding: 0;
						@include border-box;
					}
					
					&:hover .bg{
						height: 5.7rem;
						line-height: 5.7rem;
					}
				}
			}

			& .contact{
				display: none;
				& .mg-tx{
					font-size: .8rem !important;
				}
				& .mg-tx2{
					font-size: .7rem !important;
				}
			}
		}
		& .busca {
			width: 80%;
			left: 50%;
			top: 90%;
			transform: translate(-50%, -90%);
	
			& h2 {
				color: #ffffff;
				font-size: 2rem;
				text-shadow: 1px 1px $gray2;
			}
	
			& .busca-filtros_homepage {
				display: grid;
				grid-template-columns: 1fr 1.2fr 1.2fr 1fr 1fr;
				gap: .5rem;
				width: 100%;
	
				& .item_busca_filtros {
					width: 100%;
					display: block;
				}
			}
		}
	}
}

@media (max-width:550px){
	 .videoArea {
		
		.slogan_blackfriday{
			transform: none;
			left: 0;
			right: 0;
			top: 20%;
			margin-inline: 1rem;

			h2{
				text-align: center;
				font-size: 1.5rem;
			}
		}
	}

}

@media all and (max-width: 1023px){

	.headerindex{
		height: inherit;
		margin-bottom: 0;
		display: block;
		width: 100%;
		
		& .videoArea {
			z-index: 1;
			display: block;
			position: inherit;
			width: 100%;
			height: inherit;
			background-color: $colorPrimary;
			
			.slogan_blackfriday{
				top: 20%;
				h2{
					text-align: center;
				}
			}

			& .videoareaareaoverlay {
				display: block;
				width: 100%;
				background-color: $colorPrimary !important;
				position: inherit;
	
				& img{
					object-fit: cover;
					display: block;
					width: 100%;
				}
				
				& .frase {
					position: inherit;
					right: inherit;
					top: inherit;
					text-align: center;
					transform: inherit;
					width: 100%;
					display: block;
					
					& .specialfontwhite{
						line-height: 3rem !important;
						font-size: 1.5rem;
					}
				}
			}

			& .busca{
				position: inherit;
				bottom: inherit;
				left: inherit;
				top: inherit;
				transform: inherit;
				display: block;
				width: 85%;
				padding: 1rem 0;

				& h2{
					font-size: 1.4rem !important;
					font-weight: 800 !important;
				}
			
				& .busca-filtros_homepage {
					display: grid;
					grid-template-columns: 1fr;
					gap: .5rem;
					width: 100%;
		
					& .item_busca_filtros {
						width: 100%;
						display: block;
					}
				}
			}

			& .container-header-pc{
				width: 100%;
				height: inherit;
			}
		}
	}
}
