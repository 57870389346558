@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

/* SCROLL-BAR */
::-webkit-scrollbar{width: 10px; height: 10px;}
::-webkit-scrollbar-track-piece{background-color: #FFF;}
::-webkit-scrollbar-thumb:vertical{height: 10px; background-color: $gray3;}
::-webkit-scrollbar-thumb:horizontal{width: 10px; background-color: $gray3;}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.ho-title{
	font-size: 1.3rem;
}

.ho-subtitle{
	font-size: 1.1rem;
}

.ho-txt{
	font-size: 1rem;
}

.ho-mini{
	font-size: 0.9rem;
	margin-top: 3px;
    margin-bottom: 3px;
}

.font-style {
	font-family: 'Lato';
	font-size: 2.4rem;
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'Lato', sans-serif;
	background-color: white;
	color: $gray2;
	font-weight: 300;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

.specialfont{
	font-family: 'Rosmatika';
	font-size: 3rem;
	line-height: 2.5rem;
	color: black;
}

.specialfontwhite{
	font-family: 'Rosmatika';
	font-size: 2.5rem;
	line-height: 2.5rem;
	color: white;
	text-shadow: 2px 1px 3px rgba(0,0,0,.6);
}


h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $gray2;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1;

	&:hover {
		color: white;
	}
	&:visited, &:focus, &:active{
		color: $gray1;
	}
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 300;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.icon-animscale{
	&:hover{
		transform: scale(1.2);
		transition: .15s all;
		@include border-box;
	}
}

.detail-line::after {
	display: block;
	width: 2rem;
	content: '';
	height: .2rem;
	background-color: $colorPrimary;
}

.detail-dialog:after {
	display: block;
	width: 5rem;
	content: '';
	height: .3rem;
	margin-top: 1rem;
	background-color: $colorDanger;
}

.padrao-icone {
	& i {
		font-size: 45px;
		width: 70px;
		height: 70px;
		line-height: 70px;
		@include border-radius(50%);
		text-align: center;
		color: $gray1;
		border: 2px solid $gray1;
	}
}

.site-space-2-5 {
	display: table;
	width: 100%;
	height: 2.5rem; 
}

.container-fixo {
	position: fixed !important;
	bottom: 20px !important;
	right: 20px !important;
	z-index: 9997;
}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}

.bg-blackfriday{
	background-image: url('../jnh/blackfriday/bg_blackfriday.webp');
	background-color: #000 !important;
	position: relative;
	background-position: bottom;
	background-repeat: repeat;
	background-size: cover;
	min-height: 100vh;

	.container_resultados{
		h2{
			position: relative;
			color: #fff;
			font-weight: 600;
			width: min-content;
			white-space: nowrap;
			font-size: 1.85rem;
			margin-top: 1rem;
		}

		h2::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 3px;
			background-color: $colorPrimary;
		}
	}
	.faixa-blackfriday{
		width: 100%;
		min-width: none;
	}

	> div {
		display: flex;
		justify-content: center;
		.tarja_2{
			width: auto;
			margin-inline: auto !important;
			max-height: 250px;
			max-width: 100%;
			margin-top: -8rem;
		}
	}

	.blackfriday_slogan {
		font-family: "League Gothic", sans-serif;
		color: #fff;
		font-size: 2.15rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: .45rem;
	}

	.content_v{
		padding-top: 0 !important;
	}

	.parceiros_sections{
		.title {
			font-size: 2.5rem;
			font-weight: 500;
			color: #fff;
			text-align: center;
			margin-bottom: 2rem;
		}

		.grid {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(250px, min-content));
			grid-auto-rows: min-content;
			gap: 2rem;
			justify-content: center;
			min-height: 30vh;
			div{
				display: flex;
				align-items: center;
				margin: 0;
			}
			img{
				max-width: 250px;
				min-width: none;
			}
		}

	}

	#section-imoveis{
		position: relative;
		z-index: 2;
	}

	.titulo_busca{
		h1{
			color: #fff;
		}
	}

}

.no_pc{
	display: none;
}

@media (max-width:1000px){
	.bg-blackfriday{
	
		> div {
			.tarja_2{
				max-height: 150px;
				margin-top: -2rem;
			}
		}
	}
}


@media (max-width:550px){
	.no_mobile{
		display: none;
	}

	.no_pc{
		display: block !important;
		width: 100% !important;
		max-width: 100% !important;
		min-width: none;
	}
	

	.bg-blackfriday{
		background-image: url('../jnh/blackfriday/bg_mobile.png');
		background-color: #000 !important;
		position: relative;
		background-position: bottom;
		background-repeat: repeat !important;
		background-size: contain !important;
	
		.faixa-blackfriday{
			width: 100%;
			min-width: none;
			width: 1000px;
		}


		> div {
			display: flex;
			justify-content: center;
			.tarja_2{
				width: auto;
				margin-inline: auto !important;
				max-height: 130px;
				max-width: 100%;
				margin-top: -2rem;
			}
		}
	}

}

.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-primary-g{
	display: table;
	width: 100%;
	// @include gradient('right, #{lighten($colorPrimary, 5%)} 5%, #{darken($colorPrimary, 8%)}' 60%);
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorSecondary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.btns_busca_mob {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .5rem;

	& button {
		background-color: $colorPrimary;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: .5rem 0;
		border: none;
		border-radius: .2rem;
		&:hover{
			background-color: darken($colorPrimary, 5);
		}
	}
}

.btns_infras_ord {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: .5rem;
	
	& button {
		background-color: $colorSecondary;
		color: white;
		font-size: .9rem;
		text-transform: uppercase;
		font-weight: 600;
		border: none;
		border-radius: .2rem;
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:hover{
			background-color: darken($colorSecondary, 5);
		}
	}

	& .creccss select{
		width: 100%;
		display: block;
		border-radius: .2rem;
		color: $gray2;
		padding: 0 1rem;
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:hover{
			background-color: $gray5;
			color: #161616;
			border-color: #7b7b7b;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){
	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){
	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){

	html{
		font-size: 3.75vw;
	}
}


.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

.splide__pagination {
	bottom: 10rem !important;
}

@media (max-width:1023px){
	.font-style {
		font-family: 'Lato';
		font-size: 1.7rem;
		color: white;
		padding-top: 1rem;
		
	}

	.splide__pagination {
		bottom: 8rem !important;
	}

	.splide__arrow--prev{
		left: .5rem !important;
	}
	
	.splide__arrow--next{
		right: .5rem !important;
	}

	.specialfontwhite{
		font-size: 1.9rem;
	}

	#titulo_busca {
		margin: 2rem 0;
	}

	.btns_infras_ord {
		grid-template-columns: 1fr;
		gap: 0;

		& .creccss {
			margin-right: 0;
		}
	}
}

@media all and (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 0.5rem !important;
	}
	
	.splide__arrow--next{
		right: 0.5rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 0 !important;
	}
	
	.splide__arrow--next{
		right: 0 !important;
	}
}