.nav {
	display: block;
	width: 40vh;
	position: fixed;
	top: 0;
	right: -1000px;
	background: rgba($colorPrimary,0.95);
	z-index: 99991;
	color: white;
	box-shadow: 0 0 20px 0 rgba(black, .3);

	& button {
		margin-right: 1rem;
		border: none;
		background-color: transparent;
		cursor: pointer;
		transition: all .2s ease-in-out;

		& i {
			margin: 0;
			padding: 0;
			font-size: 2rem;
			color: white;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: white;

			& i{
				color: $colorPrimary;
			}
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: white;
		font-size: 1.7rem;
		font-weight: 600;
		padding: 1.5rem 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: white;
		font-size: 1.2rem;
		font-weight: 400;
		margin: 0;
		padding: .6rem 1rem;
		@include border-box;

		&:hover {
			font-weight: 500;
			color: $gray2;
			background-color: white;
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		width: 80vw;
		
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}