.formsimple-selectmultiple{
	&::after {	
		position: absolute;
		top: 2px;
		right: 13px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 13px !important;
		font-weight: 600;
		color: $colorSecondary;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: #ffffff;
		color: $gray1 !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		border-radius: .2rem;
		cursor: pointer;
	}
}