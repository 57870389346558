.imoveis-ver-promocao{

    & .anunciodepromo {
        display: flex;
        gap: 10px;
        align-items: center;

        & i, p {
            font-size: 22px;
        }
    }

    & .valores {
        background-color: $colorPrimary;
        padding: 3px 25px;
        border-radius: 10px;

        & p {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
        }
    }
}