$tamanho_img_corretor: 9rem;
.box_contato_pag_int{
	background-color: white;
	border: solid 1px darken($gray5, 3);
	padding: 1rem;
	border-radius: .5rem;

	& .miniatura-corretor {
	
		padding-bottom: 20px;
		@include border-box;
	
		& .miniatura-corretor-descricao {
			@include border-box;
			width: 100%;
			
			& h3{
				font-weight: 600;
				font-size: 1.5rem;
				padding-left: 2rem;
			}

			& .box_corretor{
				position: relative;
				background-color: $colorPrimary;
				border-radius: 1rem;
				padding: 0.5rem 0 .5rem 1.5rem;
				color: white;
				font-size: 1.1rem;
				font-weight: 800;
				width: 90%;
				box-sizing: border-box;

				& i{
					font-size: 2.3rem;
					vertical-align: middle !important;
				}

				& p{
					color: white;
					font-size: 1rem;
					font-weight: 800;
					margin: .2rem 0;
				}

				& .miniatura-corretor-img {
					position: absolute;
					width: $tamanho_img_corretor;
					right: -1.1rem;
					top: -1rem;
					& img {
						display: block;
						width: 100%;
						box-shadow: 0 0 15px 0 rgba(black, .1);
						@include border-radius(50%);
					}
				}

				& .miniatura-corretor-img2 {
					position: absolute;
					width: $tamanho_img_corretor;
					left: -1.5rem;
					top: -1rem;
					& img {
						display: block;
						width: 100%;
						box-shadow: 0 0 15px 0 rgba(black, .1);
						@include border-radius(50%);
					}
				}

				& .miniatura-corretor-img3 {
					position: absolute;
					width: $tamanho_img_corretor;
					right: -1.1rem;
					top: -2rem;
					& img {
						display: block;
						width: 100%;
						box-shadow: 0 0 15px 0 rgba(black, .1);
						@include border-radius(50%);
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.box_contato_pag_int{
		background-color: transparent;
		border: solid 1px transparent;
		padding: 0;
		border-radius: .5rem;
		& .miniatura-corretor {

			padding-bottom: 20px;
			@include border-box;
		
			& .miniatura-corretor-descricao {
				@include border-box;
				width: 100%;
				
				& h3{
					font-weight: 600;
					font-size: 1.5rem;
					padding-left: 2rem;
				}
				& .box_corretor{
					position: relative;
					background-color: $colorSecondary;
					border-top-left-radius: 18px;
					border-bottom-left-radius: 18px;
					padding: 0.5rem 1.5rem;
					color: white;
					font-size: 1.2rem;
					font-weight: 800;
					width: 100%;

					& i{
						font-size: 2.3rem;
						vertical-align: middle !important;
					}
					& p{
						color: white;
						font-size: 1rem;
						font-weight: 800;
						margin: 0;
					}
					& .miniatura-corretor-img {
						position: absolute;
						width: 8rem;
						right: -1rem;
						top: -1rem;
						& img {
							display: block;
							width: 100%;
				
							@include border-radius(50%);
						}
					}
					& .miniatura-corretor-img2 {
						position: absolute;
						width: 8rem;
						left: -2.5rem;
						top: -.3rem;
						& img {
							display: block;
							width: 100%;
				
							@include border-radius(50%);
						}
					}
				}
			}
		}
	}
}