.cond-header{
    border-top: solid 1px white;
    background: rgb(0, 167, 196);
background: linear-gradient(273deg, rgba(0, 167, 196,1) 48%, rgba(0, 167, 196,0.6979166666666667) 63%, rgba(0, 167, 196,1) 94%);
}

.hr-condo{
    margin-top: 1px;
    width: 30%;
    padding-top: 0.8rem;
}

.brd-rig{
    border-right: solid 2px $gray4;
}

@media all and (min-width:320px) and (max-width: 1023px){
    .brd-rig{
        border-right: none;
    }
}