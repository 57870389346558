.btn_simular_financiamento{
	background-color: $colorPrimary;
	width: fit-content;
	color: white;
	border: solid 1px $colorPrimary;
	padding: .5rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	border-radius: .2rem;
	user-select: none;
	cursor: pointer;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.box_form_sf{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999999999999999;
	background-color: rgba(black, .7);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	& .dialog_simulador_financiamento{
		padding: 1.5rem;
		width: fit-content;
		height: none;
		box-sizing: border-box;
		background-color: white;
		position: relative;

		& .close_form_sf{
			background-color: $colorPrimary;
			width: fit-content;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border: solid 2px white;
			position: absolute;
			top: -3%;
			right: -3%;
			transition: all .2s ease-in-out;
			cursor: pointer;

			& i{
				color: white;
				font-size: 1.1rem;
				font-weight: 600;
				line-height: normal;
				margin: 0;
			}

			&:hover{
				background-color: darken($colorPrimary, 5);
			}
		}
	
		& .top_sf{
			display: block;
			width: 100%;
	
			& h2{
				color: $colorPrimary;
				font-size: 1.5rem;
				font-weight: 600;
				text-transform: uppercase;
				text-align: center;
				margin: 0;
				margin-bottom: .5rem;
			}
	
			& .underlinexxx{
				background-color: $gray4;
				width: 50%;
				height: 2px;
			}
	
			& p{
				text-align: center;
				color: $gray2;
				font-weight: 400;
				font-size: .9rem;
			}
	
			& .imo_preco{
				text-align: center;
				color: $gray2;
				font-weight: 400;
				font-size: .9rem;
	
				& span{
					color: $colorPrimary;
					font-size: 1.2rem;
					font-weight: 600;
				}
			}
		}
	
		& form{
			display: block;
			width: 100%;
	
			& input{
				border: solid 1px $gray4;
				width: 100%;
				padding: .7rem;
				border-radius: .2rem;
				&::placeholder{
					color: $gray3 !important;
				}
			}
	
			& textarea{
				width: 100%;
				resize: none;
				border: solid 1px $gray4;
				padding: .7rem;
				border-radius: .2rem;
				min-height: 6rem;
			}
	
			& button{
				background-color: $colorPrimary;
				color: white;
				padding: .5rem 0;
				border: none;
				width: 100%;
				border-radius: .2rem;
				font-weight: 600;
				font-size: 1rem;
				text-transform: uppercase;
				cursor: pointer;
				transition: all .2s ease-in-out;
				margin: 0;
	
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}
}

.imovel-ver-preco {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	text-align: center;
}
.imovel-ver-disponivel {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
}

/*
	01 Sala, 01 Cozinha, 03 Garagens, etc..
 */
.imovel-ver-informacoes{
	& ul li {
		color: $gray1;
		margin: .5rem 0;

		& i {
			padding-right: .4rem;
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.box_form_sf{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99999999999999999;
		background-color: rgba(black, .7);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		
		& .dialog_simulador_financiamento{
			padding: .5rem 1.5rem 1.5rem 1.5rem;
			width: fit-content;
			height: 90vh;
			overflow-x: hidden;
			overflow-y: auto;
			box-sizing: border-box;
			background-color: white;
			position: relative;
	
			& .close_form_sf{
				background-color: $colorPrimary;
				width: fit-content;
				border-radius: 50%;
				width: 2rem;
				height: 2rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border: solid 2px white;
				position: inherit;
				top: inherit;
				right: inherit;
				transition: all .2s ease-in-out;
				cursor: pointer;
				margin-right: 0;
				margin-bottom: 1rem;
				& i{
					color: white;
					font-size: 1.1rem;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}
	
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		
			& .top_sf{
				display: block;
				width: 100%;
		
				& h2{
					color: $colorPrimary;
					font-size: 1.5rem;
					font-weight: 600;
					text-transform: uppercase;
					text-align: center;
					margin: 0;
					margin-bottom: .5rem;
				}
		
				& .underlinexxx{
					background-color: $gray4;
					width: 50%;
					height: 2px;
				}
		
				& p{
					text-align: center;
					color: $gray2;
					font-weight: 400;
					font-size: .9rem;
				}
		
				& .imo_preco{
					text-align: center;
					color: $gray2;
					font-weight: 400;
					font-size: .9rem;
		
					& span{
						color: $colorPrimary;
						font-size: 1.2rem;
						font-weight: 600;
					}
				}
			}
		
			& form{
				display: block;
				width: 100%;
		
				& input{
					border: solid 1px $gray4;
					width: 100%;
					padding: .7rem;
					border-radius: .2rem;
					&::placeholder{
						color: $gray3 !important;
					}
				}
		
				& textarea{
					width: 100%;
					resize: none;
					border: solid 1px $gray4;
					padding: .7rem;
					border-radius: .2rem;
					min-height: 6rem;
				}
		
				& button{
					background-color: $colorPrimary;
					color: white;
					padding: .5rem 0;
					border: none;
					width: 100%;
					border-radius: .2rem;
					font-weight: 600;
					font-size: 1rem;
					text-transform: uppercase;
					cursor: pointer;
					transition: all .2s ease-in-out;
					margin: 0;
		
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.imovel-ver-preco {
		margin-bottom:0 !important;
	}
	.imovel-ver-disponivel {
		margin-top:0 !important;
	}
}