.simuladores{
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    &:hover {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        filter: grayscale(0%);
    }
    & img{
        &:hover{
            transform: scale(1.1);
            transition: .15s all;
            @include border-box;    
        } 
    }
}