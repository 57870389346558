.footer{
	width: 100%;
	display: block;
	padding: 60px 0px;
	background: $colorPrimary;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	a {
		&:hover{
			// color: lighten(gray, 30) !important;
		}
	}
}
.footer *{
	/*color: #33181a;*/
	color: #FFF;
}
.footer p{
	margin: 4px 0px;
	line-height: 1em;
	letter-spacing: 0px;
}
.footer hr{
	background-color: rgba(255, 255, 255, 0.22);
}
.footer-content{
	display: block;
	width: 100%;
	max-width: 80rem;
	margin: auto;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.line-hgt{
	line-height: 1.5rem !important;
	& a {
		line-height: 1.5rem !important;
	}
}
.footer-content a, .footer-content a:visited{
	color: #FFF;
	font-weight: normal;
	display: inline-block;
}
.footer-content a:hover, .footer-content a:active{
	text-decoration: underline;
}

.medium, .medium:hover{
	font-size: 1.15em;
}

.small{
	font-size: 0.9em;
}

.iconesRedesSociaisFooter {
	width: 2rem;
	height: 2rem;
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}