/* MINIATURA 1 */
.b-perso{
	font-size: 1rem;
	&:hover, &:active, &:focus, &:visited{
		color: white !important;
		font-size: 1rem;
	}
}
.miniatura-lancamento {
	display: inline-block;
	vertical-align: top;
	padding: 1rem;


	& .miniatura-lancamento-img{

		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 65%;
		background-color: $gray1; 
		//border: 1px solid $colorSecondary;
		position: relative;
		cursor: pointer;

		& img{
			position: absolute;
			top: -30%;
			width: 100%;
		}

		& .miniatura-lancamento-status{
			background-color: $colorSecondary;
			position: absolute;
			left: 20px;
			bottom: 10px;
			width: 30%;
			text-align: left;
			padding: 5px 10px;
			color: #ffffff;
			text-transform: uppercase;
			font-size: 0.9rem;
			@include border-box;
		}

		& .miniatura-lancamento-detalhe {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 0;
			@include border-box;
			background-color: rgba(0,0,0, .5);
		}
	}
}

@include keyframes(lancamento_img){
	from{
		transform: translate(-50%, -50%) scale(1.0);
	}
	to{
		transform: translate(-50%, -50%) scale(1.06);
	}
}

.miniatura-lancamento-marker {
	display: inline-block;
	width: 20px !important;
	vertical-align: middle;
}

@media all and (max-width: 480px){
	.miniatura-lancamento {
	
		& .miniatura-lancamento-img{
	
			& .miniatura-lancamento-status{
				width: 40%;
			}
		}
	}
}