.pag_equipe{
    display: block;
    width: 100%;

    & h1{
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        color: $colorTerciary;
        line-height: 2rem;

        & span{
            color: $colorPrimary;
            font-weight: 600;
            font-size: 2rem;
        }
    }

    & video{
        object-fit: cover;
        display: block;
        width: 100%;
    }

    & .containerMiniaturasEquipe {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;
        
        & .miniatura_equipe{
            width: 100%;
            flex-basis: 33%;
            display: block;
            border: solid 1px $gray5;
            border-radius: .5rem;
            padding: .5rem;
            margin: 0;
    
            & img{
                object-fit: cover;
                width: 100%;
                display: block;
                border-radius: .5rem;
            }
    
            & h2{
                color: $colorPrimary;
                font-weight: 600;
                font-size: 1.5rem;
                padding: 0 .5rem;
            }
    
            & p{
                color: $gray2;
                font-weight: 500;
                font-size: .95rem;
                text-align: justify;
                margin: 0;
                padding: 0 .5rem;
            }
        }
    }

}

@media (max-width: 1023px) {
    .pag_equipe {
        & .containerMiniaturasEquipe {
            flex-direction: column;
            gap: 3rem;
        }
    }
}