.page-wrap {
	display: -webkit-box;           /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
	display: -ms-flexbox;           /* TWEENER - IE 10 */
	display: -webkit-flex;          /* NEW - Chrome */
	display: flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.main-content {
	-webkit-box-ordinal-group: 2;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 2;      /* OLD - Firefox 19- */
	-ms-flex-order: 2;              /* TWEENER - IE 10 */
	-webkit-order: 2;               /* NEW - Chrome */
	order: 2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	width: 50%;                     /* No flex here, other cols take up remaining space */
	-moz-box-flex: 1;               /* Without this, Firefox 19- expands to widest paragraph, overrides width */
	background: $colorQuarterly;
}

.main-content2 {
	-webkit-box-ordinal-group: 2;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 2;      /* OLD - Firefox 19- */
	-ms-flex-order: 2;              /* TWEENER - IE 10 */
	-webkit-order: 2;               /* NEW - Chrome */
	order: 2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	width: 55%;                     /* No flex here, other cols take up remaining space */
	-moz-box-flex: 1;               /* Without this, Firefox 19- expands to widest paragraph, overrides width */
	background: $gray5;
}

.home-text{
    font-size: 1.1rem;
}

.main-nav {
	-webkit-box-ordinal-group: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 1;      /* OLD - Firefox 19- */
	-ms-flex-order: 1;              /* TWEENER - IE 10 */
	-webkit-order: 1;               /* NEW - Chrome */
	order: 1;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	-webkit-box-flex: 1;            /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;               /* OLD - Firefox 19- */
	width: 20%;                     /* For old syntax, otherwise collapses. */
	-webkit-flex: 1;                /* Chrome */
	-ms-flex: 1;                    /* IE 10 */
	flex: 1;                        /* NEW, Spec - Opera 12.1, Firefox 20+ */
	background: $gray2;
	width: 100%;
	z-index: 2;
	background-image: url('/jnh/background/condominio.jpg');
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: 6.5%;
	z-index: 999999;
}

.main-nav2 {
	-webkit-box-ordinal-group: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 1;      /* OLD - Firefox 19- */
	-ms-flex-order: 1;              /* TWEENER - IE 10 */
	-webkit-order: 1;               /* NEW - Chrome */
	order: 1;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	-webkit-box-flex: 1;            /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;               /* OLD - Firefox 19- */
	width: 20%;                     /* For old syntax, otherwise collapses. */
	-webkit-flex: 1;                /* Chrome */
	-ms-flex: 1;                    /* IE 10 */
	flex: 1;                        /* NEW, Spec - Opera 12.1, Firefox 20+ */
	background: white;
	width: 100%;
	z-index: 2;
	background-image: url('/jnh/equipe/new/bruna_new.jpg');
	transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	filter: brightness(1.1);
}

@include keyframes(bg_index){
	from {
		@include scale(1);
	}
	to {
		@include scale(1.2);
	}
}

.servicos {
	display: block;
	width: 100%;
	background-color: lighten($colorSecondary, 5);
	padding: 5rem 1.5rem;
	@include border-box;

	& p {
		color: #ffffff;
	}
	
	& .servicos-img {
		display: block;
		width: 5rem;
		user-select: none;
		
		& img {
			display: block;
			width: 100%;
		}
	}
}

.shadow-box{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
}

.box-item{
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
	width: 60%;
	border-radius: 9%;
	text-align: center;
	padding: 1.5rem 1rem;
	min-height: 13rem;
	&:hover{
		background-color: lighten($gray5,3);
		& img{
			transform: scale(1.2);
		}
	}
	& img{
		width: 6rem;
		transition: .15s all;
	}

	& span{
		color: $gray2;
		font-size: 1.3rem;
		font-weight: 500;
		line-height: 1.3em;
	}
}

.bganuncie{
	background-image: url('/jnh/background/bganuncie.jpg');
	background-size: cover;
	background-position: center;
	height: 30rem;
	filter: brightness(100%);
	position: relative;
	cursor: pointer;
	&:hover{
		filter: brightness(110%);
	}
}

.bgencomende{
	background-image: url('/jnh/background/bgencomende.png');
	background-size: cover;
	background-position: center;
	filter: brightness(100%);
	height: 30rem;
	position: relative;
	cursor: pointer;
	&:hover{
		filter: brightness(110%);
	}
}



.textanuncie{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center !important;
	& h3{
		color: white;
		font-size: 2.5rem;
		font-weight: 600
	}

	& p{
		font-size: 1.4rem;
		color: white;
		line-height: 1.5em;
		font-weight: 500;
		text-align: center !important;
	}
	.buttonanuncie{
		text-transform: initial !important;
		font-size: 1.1rem;
		font-weight: 800
	}
}

.box_header{
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.1)');
	z-index: 99;
	position: relative;
}

.img_title_section_imoveis {
	width: 2rem;
	height: 2rem;
	vertical-align: middle;
	margin: .5rem 0;
}

@media all and (max-width: 1023px){
	.box-item{
		@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
		width: 80%;
		border-radius: 9%;
		text-align: center;
		padding: 1.5rem 1rem;
		min-height: 13rem;

		& img{
			width: 4rem;
		}

		&:hover{
			& img{
				transition: none;
				transform: none;
			}
		}
	
		& span{
			color: $gray2;
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 1.3em;
		}
	}
	/* .buttonanuncie{
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: 6%;
	} */

	.textanuncie{
		position: initial;
		transform: none;
	}
	/* .bganuncie, .bgencomende{
		height: 24rem;
	} */
}
.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}

@media all and (max-width: 1023px){
	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
	#ux_to_top{
		left: 7%;
		top: -85px;
	}
}