/* RADIO */
.boss-radio{
	transform: translateX(0px);
	transition: transform 0.15s ease;
	@include form-font();
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: #{$inputHeight}px;
	min-width: #{$inputHeight}px;
	margin: 2px 0px 2px 0px;
	padding-left: 5px;
	padding-right: 5px;
	@include border-box;
}
.boss-radio input{
	position: absolute;
	opacity: 0;
	z-index: -1;
	width: 1px;
	height: 1px;
}
.boss-radio label{
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	font-size: $inputTextSize;
	color: $inputTextColor;
	text-align: left;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	@include user-select(none);
}
.boss-radio label span{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	width: #{$inputHeight - ($inputBorder * 2) - 6}px;
	height: #{$inputHeight - ($inputBorder * 2) - 6}px;
	border: #{$inputBorderCheckbox}px solid #AAA;
	border-radius: 50%;
	z-index: 1;
	@include border-box;
}

.boss-radio input:checked + label{
	font-weight: bold;
}

.boss-radio input + label span::before{
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	content: "";
	text-align: center;
	border-radius: 50%;
	width: 2px;
	height: 2px;
	background-color: #AAA;
	opacity: 0;
	transform: translateX(-50%) translateY(-50%) scale(0);
	animation: radio_out 0.3s;
	animation-fill-mode: forwards;
	transform-origin: center center;
}
.boss-radio input:checked + label span::before{
	animation: radio_in 0.3s;
	animation-fill-mode: forwards;
	transform-origin: center center;

}

.boss-radio-white{
	& label{
		color: $colorWhite !important;
		& span{
			color: $colorWhite !important;
			border-color: $colorWhite !important;

			&::before{
				background-color:  $colorWhite !important;
			}
		}
	}
}

/* DISABLED */
.boss-radio input:disabled + label{
	cursor: default !important;
	color: $gray4 !important;
}
.boss-radio input:disabled + label span{
	border-color: $gray4 !important;
}

@keyframes radio_in{
	0% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%);
		width: 2px;
		height: 2px;
	}
	50%{
		opacity: 1;
		transform: translateX(-50%) translateY(-50%);
		width: 14px;
		height: 14px;
	}
	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%);
		width: 10px;
		height: 10px;
	}
}
@keyframes radio_out{
	0% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%);
		width: 14px;
		height: 14px;
	}
	100% {
		opacity: 0;
		transform: translateX(-50%) translateY(-50%);
		width: 2px;
		height: 2px;
	}
}