.busca-filtros{
	display: block;
	width: 100%;
	background-color: white;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}

#busca-filtros{
	background-color: white;
	overflow: auto;
	width: 40vw;
	height: 40vh;
	padding: .5rem;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 1.5rem;

	& .container_infras_buscaInterna {
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		border-radius: .2rem;
		
		& .titleInfraGroup {
			font-size: .9rem;
			font-weight: 400;
			color: $gray1;
			padding: .7rem 1rem;
			margin: 0;
			position: relative;
			cursor: pointer;
			transition: all .2s ease-in-out;
			user-select: none;
			border-radius: .3rem;
	
			& i{
				position: absolute;
				right: 1.5rem;
				color: $colorSecondary;
				top: 40%;
				transition: all .2s ease-in-out;
			}
	
			&:hover{
				background-color: lighten($gray5, 2);
			}
		}
	
		& .infraGroups{
			padding: .5rem;
	
			& label{
				font-size: .9rem !important;
				font-weight: 400;
				color: $gray2;

				& span{
					width: 11px !important;
					height: 11px !important;
					float: none !important;
					border-radius: 50%;
				}
			}
		}
	}
}

.btns_ver_resultados_infras {
	display: block;
	width: 100%;
	padding-top: 1rem;

	& .btn_pc_Infras {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		
	}
}

.btn_padrao{
	background-color: white;
	padding: .5rem 1rem;
	color: $gray2;
	font-size: .9rem;
	font-weight: 500 !important;
	cursor: pointer;
	transition: all .2s ease-in-out;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	text-transform: uppercase;

	& i {
		color: $colorTerciary;
	}
	
	&:hover{
		border-color: $colorTerciary;
	}
}

.btn_padrao_primary {
	background-color: $colorPrimary;
	padding: .5rem 1rem;
	color: white;
	font-size: .8rem;
	text-transform: uppercase;
	font-weight: 600 !important;
	cursor: pointer;
	border: solid 1px $colorPrimary;
	border-radius: .2rem;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: darken($colorPrimary, 2);
	}
}

.height-but{
	height: 3rem !important;
}

.bg-person{
	background-color: $colorPrimary;
	border-top: solid 0.5px white;
}

.heigt{
	height: 2.3rem !important;
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}

@media all and (min-width: 1201px) and (max-width: 1370px){
	#busca-filtros{
		background-color: white;
		overflow: auto;
		height: 56vh;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	
	#busca-filtros{
		background-color: white;
		overflow: auto;
		height: 52vh;
	}
}

@media all and (max-width: 1023px){
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	#busca-filtros{
		width: 80vw;
		height: 50vh;
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}

.busca_blackfriday {
	display: flex;
	gap: 1rem;
	color: #000 !important;

	.select_blackfriday_container {
		position: relative;
		background: $gray5;
		flex: 1;
		cursor: pointer;
		flex: 1;
		color: #000;
		font-weight: 500;
	}

	.select_blackfriday{
		padding: 0.5rem;
		display: flex;
		justify-content: space-between;
	}
	
	.options_blackfriday_container{
		border-radius: 5px;
		padding: .5rem;
		position: absolute;
		background: $gray5;
		border: 1px solid $gray1;
		top: 40px;
		left: 0;
		right: 0;
		z-index: 100;
		display: grid;
		gap: .5rem;
		max-height: 200px;
		overflow-y: scroll;
		overflow-x: hidden;
		h3 {	
			font-size: 1rem;
			color: #000 !important;
			line-height: normal;
			font-family: inherit !important;
			margin: 0 !important;
		}
		& > div {
			padding-left: 1rem;
			color: $gray2;
			display: grid;
			gap: .5rem;
		}
		div{
			width: 100%;
		}
	}

	.buttons_container{
		display: flex;
		gap: 1rem;

		& > button:nth-child(1){
			height: auto;
			display: flex;
			align-items: center;
			gap: .5rem;
		}
	}

}


@media (max-width: 1024px){


	.busca_blackfriday{
		flex-direction: column;

		.select_blackfriday_container {

			width: 100%;

			.select_blackfriday{
				padding-right: 1rem;
			}
		}
	}
}